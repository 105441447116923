<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="540"
      max-height="500"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
    >
      <v-card class="pt-12 overflow-hidden">
        <v-row
          justify="center"
          class="mb-4"
          :class="$vuetify.breakpoint.smAndDown ? 'mt-16' : ''"
        >
          <svg
            width="80"
            height="70"
            viewBox="0 0 50 59"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M53.6602 23.6604C57.1616 36.7276 49.4069 50.1591 36.3397 53.6604C23.2725 57.1618 9.84109 49.4071 6.33974 36.3399C2.8384 23.2727 10.5931 9.84129 23.6602 6.33994C36.7274 2.8386 50.1589 10.5933 53.6602 23.6604ZM8.6604 35.7181C11.8183 47.5037 23.9324 54.4977 35.7179 51.3398C47.5035 48.1819 54.4975 36.0678 51.3396 24.2823C48.1817 12.4967 36.0676 5.50267 24.2821 8.6606C12.4965 11.8185 5.50247 23.9326 8.6604 35.7181Z"
              fill="#1E853A"
            />
            <path
              d="M18.7968 30.8704L25.3912 37.5198L40.4453 22.5723"
              stroke="#1E853A"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-row>
        <div
          id="messageHeaderLabel"
          class="d-flex justify-center transition-swing font-weight-bold text-h5 mb-4"
        >
          {{ $t("congratulations") }}
        </div>
        <span
          id="messageHeaderLabel"
          class="d-flex text-center justify-center transition-swing font-weight-medium mb-4"
          :class="
            $vuetify.breakpoint.smAndDown ? 'text-subtitle-2 mx-12' : 'mx-2'
          "
        >
          {{
            !showSuspendMsg ? $t("yourTicketSubmitted") : $t("suspendTicketMsg")
          }}
        </span>
        <v-row justify="center" class="my-6">
          <img
            v-if="center == 'JULIE'"
            style="border: 1px solid #e0e0e0; border-radius: 8%"
            src="@/assets/images/julie-811-center.svg"
            role="img"
            height="62"
            alt="Julie 811 Center Logo"
          />
          <img
            v-if="center == 'OUPS'"
            style="border: 1px solid #e0e0e0; border-radius: 8%"
            src="@/assets/images/ohio-811.svg"
            role="img"
            alt="Ohio 811 Center Logo"
          />
          <img
            v-if="center == 'UTAH'"
            style="border: 1px solid #e0e0e0; border-radius: 8%"
            src="@/assets/images/utah-811.svg"
            role="img"
            alt="Utah 811 Center Logo"
          />
          <img
            v-if="center == 'UNCC'"
            class="pa-2"
            style="border: 1px solid #e0e0e0; border-radius: 8%"
            src="@/assets/images/CO811.svg"
            role="img"
            height="62"
            alt="CO811 Center Logo"
          />
          <img
            v-if="center == 'NCOCC'"
            src="@/assets/images/NC811.svg"
            role="img"
            height="62"
            alt="NC811 Center Logo"
          />
          <img
            v-if="center == 'VUPS'"
            class="pa-1"
            style="border: 1px solid #e0e0e0; border-radius: 8%"
            src="@/assets/images/VA811.svg"
            role="img"
            height="62"
            alt="VA811 Center Logo"
          />
          <img
            v-if="center === 'USAS'"
            class="pa-4"
            style="border: 1px solid #e0e0e0; border-radius: 8%"
            src="@/assets/images/USAS-digalert-logo.svg"
            role="img"
            alt="USAS Digalert Logo"
          />
        </v-row>
        <p v-if="ticketSubmissionMessage" class="h6 text-wrap text-center mb-4 font-weight-bold pb-4 mx-14">
          <small>
            {{ticketSubmissionMessage}}
          </small>
        </p>
        <v-row justify="center">
          <v-card
            outlined
            width="340"
            max-height="70"
            style="border: 1px solid #bdbdbd; background-color: #fafafa"
            class="d-flex justify-center py-2 py-auto font-weight-medium"
            elevation="0"
          >
            {{ $t("ticket") }} - {{ ticketNumber }}
          </v-card>
        </v-row>
        <v-row justify="space-around" class="mt-8 mb-8 mx-auto">
          <v-col cols="4" sm="4" lg="4" md="4" class="mx-auto">
            <v-btn
              color="primary"
              block
              class="text-none body-2 rounded-lg"
              @click="closeDialog"
              >{{ $t("done") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    ticketNumber: {
      type: String,
      default: () => " ",
    },
    dialog: {
      type: Boolean,
      default: () => true,
    },
    showSuspendMsg: {
      type: Boolean,
      default: () => false,
    },
    ticketSubmissionMessage: {
      type: String,
      default: () => " ",
    }
  },
  data() {
    return {
      center: this.$store.state.Ticket.ticketData.center,
    };
  },
  methods: {
    closeDialog() {
      this.$emit("onCloseDialog");
    },
  },
};
</script>

<style lang="scss" scoped></style>
