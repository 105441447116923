var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "540",
            "max-height": "500",
            fullscreen: _vm.$vuetify.breakpoint.smAndDown,
            persistent: "",
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "pt-12 overflow-hidden" },
            [
              _c(
                "v-row",
                {
                  staticClass: "mb-4",
                  class: _vm.$vuetify.breakpoint.smAndDown ? "mt-16" : "",
                  attrs: { justify: "center" },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "80",
                        height: "70",
                        viewBox: "0 0 50 59",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M53.6602 23.6604C57.1616 36.7276 49.4069 50.1591 36.3397 53.6604C23.2725 57.1618 9.84109 49.4071 6.33974 36.3399C2.8384 23.2727 10.5931 9.84129 23.6602 6.33994C36.7274 2.8386 50.1589 10.5933 53.6602 23.6604ZM8.6604 35.7181C11.8183 47.5037 23.9324 54.4977 35.7179 51.3398C47.5035 48.1819 54.4975 36.0678 51.3396 24.2823C48.1817 12.4967 36.0676 5.50267 24.2821 8.6606C12.4965 11.8185 5.50247 23.9326 8.6604 35.7181Z",
                          fill: "#1E853A",
                        },
                      }),
                      _c("path", {
                        attrs: {
                          d: "M18.7968 30.8704L25.3912 37.5198L40.4453 22.5723",
                          stroke: "#1E853A",
                          "stroke-width": "3",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round",
                        },
                      }),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-center transition-swing font-weight-bold text-h5 mb-4",
                  attrs: { id: "messageHeaderLabel" },
                },
                [_vm._v(" " + _vm._s(_vm.$t("congratulations")) + " ")]
              ),
              _c(
                "span",
                {
                  staticClass:
                    "d-flex text-center justify-center transition-swing font-weight-medium mb-4",
                  class: _vm.$vuetify.breakpoint.smAndDown
                    ? "text-subtitle-2 mx-12"
                    : "mx-2",
                  attrs: { id: "messageHeaderLabel" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        !_vm.showSuspendMsg
                          ? _vm.$t("yourTicketSubmitted")
                          : _vm.$t("suspendTicketMsg")
                      ) +
                      " "
                  ),
                ]
              ),
              _c(
                "v-row",
                { staticClass: "my-6", attrs: { justify: "center" } },
                [
                  _vm.center == "JULIE"
                    ? _c("img", {
                        staticStyle: {
                          border: "1px solid #e0e0e0",
                          "border-radius": "8%",
                        },
                        attrs: {
                          src: require("@/assets/images/julie-811-center.svg"),
                          role: "img",
                          height: "62",
                          alt: "Julie 811 Center Logo",
                        },
                      })
                    : _vm._e(),
                  _vm.center == "OUPS"
                    ? _c("img", {
                        staticStyle: {
                          border: "1px solid #e0e0e0",
                          "border-radius": "8%",
                        },
                        attrs: {
                          src: require("@/assets/images/ohio-811.svg"),
                          role: "img",
                          alt: "Ohio 811 Center Logo",
                        },
                      })
                    : _vm._e(),
                  _vm.center == "UTAH"
                    ? _c("img", {
                        staticStyle: {
                          border: "1px solid #e0e0e0",
                          "border-radius": "8%",
                        },
                        attrs: {
                          src: require("@/assets/images/utah-811.svg"),
                          role: "img",
                          alt: "Utah 811 Center Logo",
                        },
                      })
                    : _vm._e(),
                  _vm.center == "UNCC"
                    ? _c("img", {
                        staticClass: "pa-2",
                        staticStyle: {
                          border: "1px solid #e0e0e0",
                          "border-radius": "8%",
                        },
                        attrs: {
                          src: require("@/assets/images/CO811.svg"),
                          role: "img",
                          height: "62",
                          alt: "CO811 Center Logo",
                        },
                      })
                    : _vm._e(),
                  _vm.center == "NCOCC"
                    ? _c("img", {
                        attrs: {
                          src: require("@/assets/images/NC811.svg"),
                          role: "img",
                          height: "62",
                          alt: "NC811 Center Logo",
                        },
                      })
                    : _vm._e(),
                  _vm.center == "VUPS"
                    ? _c("img", {
                        staticClass: "pa-1",
                        staticStyle: {
                          border: "1px solid #e0e0e0",
                          "border-radius": "8%",
                        },
                        attrs: {
                          src: require("@/assets/images/VA811.svg"),
                          role: "img",
                          height: "62",
                          alt: "VA811 Center Logo",
                        },
                      })
                    : _vm._e(),
                  _vm.center === "USAS"
                    ? _c("img", {
                        staticClass: "pa-4",
                        staticStyle: {
                          border: "1px solid #e0e0e0",
                          "border-radius": "8%",
                        },
                        attrs: {
                          src: require("@/assets/images/USAS-digalert-logo.svg"),
                          role: "img",
                          alt: "USAS Digalert Logo",
                        },
                      })
                    : _vm._e(),
                ]
              ),
              _vm.ticketSubmissionMessage
                ? _c(
                    "p",
                    {
                      staticClass:
                        "h6 text-wrap text-center mb-4 font-weight-bold pb-4 mx-14",
                    },
                    [
                      _c("small", [
                        _vm._v(" " + _vm._s(_vm.ticketSubmissionMessage) + " "),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "v-row",
                { attrs: { justify: "center" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass:
                        "d-flex justify-center py-2 py-auto font-weight-medium",
                      staticStyle: {
                        border: "1px solid #bdbdbd",
                        "background-color": "#fafafa",
                      },
                      attrs: {
                        outlined: "",
                        width: "340",
                        "max-height": "70",
                        elevation: "0",
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("ticket")) +
                          " - " +
                          _vm._s(_vm.ticketNumber) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "mt-8 mb-8 mx-auto",
                  attrs: { justify: "space-around" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "mx-auto",
                      attrs: { cols: "4", sm: "4", lg: "4", md: "4" },
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "text-none body-2 rounded-lg",
                          attrs: { color: "primary", block: "" },
                          on: { click: _vm.closeDialog },
                        },
                        [_vm._v(_vm._s(_vm.$t("done")) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }